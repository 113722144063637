import * as SearchTelemetryTypes from "../../types/searchtelemetry.d";

export default class SearchTelemetryData
    implements SearchTelemetryTypes.ISearchTelemetryData
{
    private term: string;
    private origin: string;
    private contentType: string;
    private salesArticleNo: string;
    private position: number;
    private pageViewLogDataId: string;
    private portal: string;
    private culture: string;
    private eventTypeField: SearchTelemetryTypes.EventType =
        SearchTelemetryTypes.EventType.Unknown;

    get eventType(): SearchTelemetryTypes.EventType {
        return this.eventTypeField;
    }

    constructor(
        stm: string,
        pageViewLogDataId: string,
        portal: string,
        culture: string
    ) {
        this.pageViewLogDataId = pageViewLogDataId;
        this.portal = portal;
        this.culture = culture;
        this.setData(stm);
    }

    public setTerm(term: string): void {
        this.term = term;
    }

    private setData(stm: string) {
        const keyValue: string[] = stm.split(";");
        keyValue.forEach((element: string) => {
            const split: string[] = element.split("=");
            const key: string = split[0];
            const value: string = split[1];

            switch (key) {
                case "Term":
                    // term can contain semicolon which is escaped via some similar unicode char
                    this.term = value.replace("﹔", ";");
                    break;
                case "Origin":
                    this.origin = value;
                    break;
                case "ContentType":
                    this.contentType = value;
                    break;
                case "Position":
                    this.position = parseInt(value, 10);
                    break;
                case "SalesArticleNo":
                    this.salesArticleNo = value;
                    break;
                default:
                    // ignore new keys
                    break;
            }
        });

        if (this.salesArticleNo)
            this.eventTypeField = SearchTelemetryTypes.EventType.SearchUsed;
        else if (this.origin)
            this.eventTypeField = SearchTelemetryTypes.EventType.HymUsed;
    }
}
