import * as React from "react";
import * as PromotionType from "../../types/promotion";

import ChevronRight from "../../Assets/svg/Chevron_right_icon";

import "./promotion.scss";

export default class Promotion extends React.Component<
    PromotionType.IPromotionProps,
    PromotionType.IPromotionState
> {
    constructor(props) {
        super(props);

        this.state = {
            logoUrl: "",
            headline: "",
            buttonCTA: "",
            buttonLink: ""
        };
    }

    public componentDidMount() {
        this.setState({
            logoUrl: this.createCdnUrl(this.props.promotion.logoUrl),
            headline: this.props.promotion.headline,
            buttonCTA: this.props.promotion.cta,
            buttonLink: this.addItemOrigin(this.props.promotion.link)
        });
    }

    public componentDidUpdate(prevProps): void {
        if (prevProps.promotion.cta !== this.props.promotion.cta) {
            this.setState({
                logoUrl: this.createCdnUrl(this.props.promotion.logoUrl),
                headline: this.props.promotion.headline,
                buttonCTA: this.props.promotion.cta,
                buttonLink: this.addItemOrigin(this.props.promotion.link)
            });
        }
    }

    public render() {
        return (
            <div className="feature-promotion">
                <div className="promotion-logo-container">
                    <img
                        className="promotion-logo"
                        src={this.state.logoUrl}
                    ></img>
                </div>
                <div className="promotion-headline-container">
                    <div className="promotion-headline-inner-container">
                        <span className="promotion-headline">
                            {this.state.headline}
                        </span>
                    </div>
                </div>
                <div className="promotion-button-container">
                    <a
                        className="promotion-button"
                        href={this.state.buttonLink}
                        onClick={this.props.onClick}
                        data-testid={`ssw-button-${this.props.promotion.name.toLowerCase()}`}
                    >
                        <div className="promotion-button-text">
                            <div className="promotion-button-content">
                                {this.state.buttonCTA}
                            </div>
                            <div className="promotion-button-icon">
                                <ChevronRight />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        );
    }

    private createCdnUrl(assetsUrl: string): string {
        const origin = window.location.origin;
        const startIndex = assetsUrl.indexOf("/documents/");
        let assetLocation = "/assets/wf/images_preview/TwoColsRightFullscreen";

        if (origin.indexOf("preview") < 0) {
            assetLocation = "/assets/wf/images/TwoColsRightFullscreen";
        }

        return `${origin}${assetLocation}${assetsUrl.substring(startIndex)}`;
    }

    private addItemOrigin(link: string): string {
        return link.replace("?", "?itemorigin=SEARCH&");
    }
}
