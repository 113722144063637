// GetPageViewLogDataId implemented as Singleton

import * as PageViewLogDataIdTypes from "../../types/pageviewlogdataid";

export default class PageViewLogDataId
    implements PageViewLogDataIdTypes.IPageViewLogDataId
{
    private static instance: PageViewLogDataIdTypes.IPageViewLogDataId;

    private pageViewLogDataId: string;

    constructor() {
        if (PageViewLogDataId.instance)
            throw new Error(
                `Instantiation of GetPageViewLogDataId failed:
                Use GetPageViewLogDataId.getInstance()
                instead of new GetPageViewLogDataId()`
            );
    }

    public static getInstance(): PageViewLogDataIdTypes.IPageViewLogDataId {
        if (!PageViewLogDataId.instance)
            PageViewLogDataId.instance = new PageViewLogDataId();

        return PageViewLogDataId.instance;
    }

    public get(): string {
        if (!this.pageViewLogDataId) this.set();

        return this.pageViewLogDataId;
    }

    private set(): void {
        this.pageViewLogDataId =
            document.documentElement.dataset.pageViewLogDataId;
    }
}
