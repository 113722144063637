import * as debounceSsw from "lodash/debounce";
import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import ReactModal from "react-modal";
import SearchBox from "./searchbox/searchbox";
import SearchBoxIcon from "./searchboxicon/searchboxicon";
import SearchBoxGrid from "./searchboxgrid/searchboxgrid";
import SearchTelemetry from "../util/searchtelemetry/searchtelemetry";
import PageViewLogDataId from "../util/pageviewlogdataid/pageviewlogdataid";

global.debounceSsw = debounceSsw;
global.React = React;
global.ReactDOM = ReactDOM;
global.ReactModal = ReactModal;
global.SearchBox = SearchBox;
global.SearchBoxIcon = SearchBoxIcon;
global.SearchBoxGrid = SearchBoxGrid;
global.ReactDOMServer = ReactDOMServer;
global.SearchTelemetry = SearchTelemetry;
global.PageViewLogDataId = PageViewLogDataId;
