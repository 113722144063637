import * as React from "react";
import * as ReactModal from "react-modal";
import * as ModalTypes from "../../types/modal";
import "./modal.scss";

export default class Modal extends React.Component<
    ModalTypes.IModalProps,
    ModalTypes.IModalState
> {
    constructor(props) {
        super(props);
    }

    public render() {
        ReactModal.setAppElement("body");
        let className: string = "scope-ssw overlay" + this.props.tenant;
        if (this.props.theme) className = className + ` ${this.props.theme}`;
        if (this.props.featureToggles)
            className = className + ` ${this.props.featureToggles}`;

        return (
            <div>
                <ReactModal
                    isOpen={this.props.open}
                    className="ssw-modal"
                    overlayClassName={className}
                    bodyOpenClassName={"scope-ssw_ReactModal__Body--open"}
                >
                    <div className="close" onClick={this.props.closeModal}>
                        <svg
                            className="old-close-x"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41
                                17.59 19 19 17.59 13.41 12z"
                            />
                        </svg>
                        <svg
                            className="close-x"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 44.99997 45"
                        >
                            <path
                                className="circleoutline"
                                d="M22.5,1A21.5,21.5,0,1,1,1,22.5,21.52428,21.52428,0,0,1,
                                  22.5,1m0-1A22.5,22.5,0,1,0,45,22.5,22.49993,22.49993,0,0,0,22.5,0Z"
                            />
                            <path
                                d="M23.77313,22.49976l6.86328-6.86329A.90011.90011,0,0,0,29.364,14.363l-6.86377,
                                6.86377L15.63641,14.363A.90011.90011,0,0,0,14.364,15.63647l6.86328,6.86329L14.364,
                                29.363a.90011.90011,0,1,0,1.27246,1.27343l6.86377-6.86376L29.364,
                                30.63647A.90011.90011,0,0,0,30.63641,29.363Z"
                            />
                        </svg>
                    </div>
                    <div className="ssw-modal-content">
                        <label className="ssw-label">
                            {this.decodeHTML(this.props.l10n.dataIncomplete)}
                        </label>
                        <div className="headline-line" />
                        <p className="text">
                            {this.decodeHTML(this.props.l10n.enterSearchTerm)}
                        </p>
                        <br />
                        <button
                            className="button-ok"
                            onClick={this.props.closeModal}
                        >
                            {this.decodeHTML(this.props.l10n.ok)}
                        </button>
                    </div>
                </ReactModal>
            </div>
        );
    }

    private decodeHTML(value: string): string {
        return value.replace(/&#(\d+);/g, (match, dec) => {
            return String.fromCharCode(dec);
        });
    }
}
