import Search2021 from "../../Assets/svg/Search_2021_icon";

import { ApplicationShellWrapper } from "@escid-esmkt/applicationshellwrapper";
import * as debounceSsw from "lodash/debounce";
import * as React from "react";
import * as ReactModal from "react-modal";
import * as SearchBoxTypes from "../../types/searchbox";

import Modal from "../modal/modal";
import SearchTelemetry from "../../util/searchtelemetry/searchtelemetry";
import Suggestions from "../suggestions/suggestions";

import "./searchbox.scss";
import Search from "../../util/search";

export default class SearchBox extends Search<
    SearchBoxTypes.ISearchBoxProps,
    SearchBoxTypes.ISearchBoxStates
> {
    private appShellWrapper: ApplicationShellWrapper;

    constructor(props: any) {
        super(props);

        this.state = {
            focus: false,
            searchTerm: "",
            showModal: false,
            suggestions: [],
            featurePromotion: "",
            topSearchQueries: [],
            visibility: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeyPressed = this.handleKeyPressed.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.activateFocus = this.activateFocus.bind(this);
        this.deactivateFocus = this.deactivateFocus.bind(this);
        this.toggleVisibility = this.toggleVisibility.bind(this);

        this.appShellWrapper = ApplicationShellWrapper.getInstance();
    }

    public componentDidMount(): void {
        this.appShellWrapper.subscribeTo(
            "ESMKT.HeadR.FlyoutClosing",
            (payload) => {
                this.clearSearchTermForFlyout(payload);
            },
            "ESMKT.HeadR.FlyoutClosing"
        );

        this.setState({
            searchTerm: this.inputRef.current.value || ""
        });

        this.inputRef.current.blur();
        document.addEventListener("mousedown", this.handleClick, false);

        const flyoutIcon = document.querySelector(
            ".scope-ssw.mkt-headr-icon-wrapper"
        );
        flyoutIcon.addEventListener("click", () => {
            if (this.searchBoxRef.current.classList.contains("flyout"))
                if (this.state.focus) {
                    this.inputRef.current.blur();
                    setTimeout(() => {
                        this.setState({ focus: false });
                    }, 200);
                } else {
                    this.inputRef.current.focus();
                    setTimeout(() => {
                        this.setState({ focus: true });
                    }, 200);
                }
        });

        const searchTelemetry = SearchTelemetry.getInstance(
            this.props.portal,
            this.props.culture
        );
    }

    public componentDidUpdate(): void {
        this.bindKeyboardNavigation();
    }

    public componentWillUnmount(): void {
        document.removeEventListener("mousedown", this.handleClick, false);
    }

    public render() {
        ReactModal.setAppElement("body");
        const { focus } = this.state;
        const notShowSuggestion =
            !this.areSuggestionsInResponse() || !this.state.searchTerm;
        const visible = this.state.visibility;
        const theme = this.props.theme ? " " + this.props.theme : "";
        const isMedium = this.props.view === "medium";

        return (
            <div
                className={
                    "searchbox" +
                    ` ${this.props.view}` +
                    ` ${focus ? "focus" : ""}` +
                    ` ${this.props.tenant}` +
                    ` ${theme} `
                }
                ref={this.searchBoxRef}
            >
                {this.props.view === "small" ? (
                    <div className="header-area">
                        <div
                            className={`button-small ${visible ? "white" : ""}`}
                            onClick={this.toggleVisibility}
                        >
                            <Search2021 />
                        </div>
                    </div>
                ) : null}
                <div
                    className={
                        this.hasMultipleSuggestionLists()
                            ? "search large"
                            : "search"
                    }
                >
                    <form
                        className={`${visible ? "transform" : ""}`}
                        method="get"
                        action={this.props.l10n.searchTarget}
                        acceptCharset="utf-8"
                        onSubmit={this.handleSubmit}
                    >
                        <input
                            ref={this.inputRef}
                            className="ssw-input"
                            name="query"
                            type="text"
                            placeholder={
                                focus || (this.state.searchTerm && !isMedium)
                                    ? ""
                                    : this.defaultTerm()
                            }
                            maxLength={50}
                            value={
                                focus || (this.state.searchTerm && !isMedium)
                                    ? this.state.searchTerm
                                    : ""
                            }
                            onChange={this.handleChange}
                            onFocus={this.activateFocus}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="none"
                            spellCheck="false"
                        />
                        <button className="ssw-button" type="submit">
                            <Search2021 />
                        </button>
                    </form>
                </div>
                {!notShowSuggestion && (
                    <>
                        <div
                            className={"ssw-backdrop"}
                            onClick={this.deactivateFocus}
                        />
                        <div
                            className={
                                this.hasMultipleSuggestionLists()
                                    ? "suggestions multiple-lists"
                                    : "suggestions"
                            }
                        >
                            <Suggestions
                                trackingEndpoint={this.props.trackingEndpoint}
                                portal={this.props.portal}
                                culture={this.props.culture}
                                ref={this.suggestionRef}
                                suggestions={this.state.suggestions}
                                searchTerm={this.state.searchTerm}
                                showMultipleSuggestionLists={this.hasMultipleSuggestionLists()}
                                articlesSuggestHeadline={this.decodeHTML(
                                    this.props.l10n.articlesSuggestHeadline
                                )}
                                contentSuggestHeadline={this.decodeHTML(
                                    this.props.l10n.contentSuggestHeadline
                                )}
                                featurePromotion={this.state.featurePromotion}
                                trouserFinderHeadline={this.decodeHTML(
                                    this.props.l10n.trouserFinderHeadline
                                )}
                                trouserFinderCTA={this.decodeHTML(
                                    this.props.l10n.trouserFinderCTA
                                )}
                                trouserFinderLink={this.decodeHTML(
                                    this.props.l10n.trouserFinderLink
                                )}
                                trouserFinderShortLink={this.decodeHTML(
                                    this.props.l10n.trouserFinderShortLink
                                )}
                                trouserFinderLogo={this.decodeHTML(
                                    this.props.l10n.trouserFinderLogo
                                )}
                                jacketFinderCTA={this.decodeHTML(
                                    this.props.l10n.jacketFinderCTA
                                )}
                                jacketFinderHeadline={this.decodeHTML(
                                    this.props.l10n.jacketFinderHeadline
                                )}
                                jacketFinderLink={this.decodeHTML(
                                    this.props.l10n.jacketFinderLink
                                )}
                                jacketFinderShortLink={this.decodeHTML(
                                    this.props.l10n.jacketFinderShortLink
                                )}
                                jacketFinderLogo={this.decodeHTML(
                                    this.props.l10n.jacketFinderLogo
                                )}
                                shoeFinderCTA={this.decodeHTML(
                                    this.props.l10n.shoeFinderCTA
                                )}
                                shoeFinderHeadline={this.decodeHTML(
                                    this.props.l10n.shoeFinderHeadline
                                )}
                                shoeFinderLink={this.decodeHTML(
                                    this.props.l10n.shoeFinderLink
                                )}
                                shoeFinderShortLink={this.decodeHTML(
                                    this.props.l10n.shoeFinderShortLink
                                )}
                                shoeFinderLogo={this.decodeHTML(
                                    this.props.l10n.shoeFinderLogo
                                )}
                                topSearchQueries={this.state.topSearchQueries}
                                topSearchQueriesHeadline={this.decodeHTML(
                                    this.props.l10n.topSearchQueriesHeadline
                                )}
                                searchTarget={this.props.l10n.searchTarget}
                            />
                        </div>
                    </>
                )}
                {(this.props.view === "wide" ||
                    this.props.view === "medium") && (
                    <Modal
                        open={this.state.showModal}
                        tenant={this.props.tenant}
                        theme={this.props.theme}
                        closeModal={this.hideModal}
                        l10n={this.props.l10n}
                        featureToggles={this.props.featureToggles}
                    />
                )}
            </div>
        );
    }

    private clearSearchTermForFlyout(flyoutName: string): void {
        if (flyoutName === "SearchBoxFlyout") {
            this.setState({ searchTerm: "" });
        }
    }

    private hideModal = () => {
        this.setState({ showModal: false });
    };

    private defaultTerm(): string {
        return this.props.view === "medium"
            ? ""
            : this.decodeHTML(this.props.l10n.defaultTerm);
    }

    private decodeHTML(value: string): string {
        return value.replace(/&#(\d+);/g, (match, dec) => {
            return String.fromCharCode(dec);
        });
    }

    private handleChange(event): void {
        if (!event || !event.target) {
            this.resetSuggestionState();
            return;
        }

        const validSearchTerm =
            event.target.value.length > 50
                ? event.target.value.slice(0, 50)
                : event.target.value;
        this.setState({ searchTerm: validSearchTerm });
        if (event.target.value) this.debouncedFetchSuggestions(event);
        else this.resetSuggestionState();
    }

    private debouncedFetchSuggestions(event): void {
        event.persist();

        if (!this.debouncedFetchSuggestionsFunc)
            this.debouncedFetchSuggestionsFunc = debounceSsw(() => {
                this.fetchSuggestion(event);
            }, 250);

        this.debouncedFetchSuggestionsFunc();
    }

    private fetchSuggestion(event): void {
        if (
            !event ||
            !event.target ||
            !event.target.value ||
            !event.target.value.trim().length
        ) {
            this.resetSuggestionState();
            return;
        }

        const searchTerm: string = event.target.value;
        if (searchTerm.length > 50) return;

        const topSearchQueriesLimit: number = this.isDesktop ? 10 : 5;

        const headers = this.pageViewLogDataId.get()
            ? {
                  "X-PageView-ID": this.pageViewLogDataId.get(),
                  "X-Feature-Toggles": "ShopSearchEnableTopSearchQueries=1"
              }
            : {};

        fetch(
            `${this.getSuggestionUrl()}?searchTerm=${encodeURIComponent(
                searchTerm
            )}&topSearchQueriesLimit=${topSearchQueriesLimit}`,
            { headers }
        )
            .then((response) => response.json())
            .then(
                (response) => {
                    if (
                        (response && response.clientSuggestionsList?.length) ||
                        response.topSearchQueries?.length
                    )
                        this.setState({
                            suggestions: response.clientSuggestionsList,
                            featurePromotion: response.featurePromotion,
                            topSearchQueries: response.topSearchQueries
                        });
                    else this.resetSuggestionState();
                },
                (error) => {
                    this.resetSuggestionState();
                }
            );
    }

    private areSuggestionsInResponse(): boolean {
        return (
            this.state.suggestions.some(
                (list) => list.clientSuggestion.length > 0
            ) || this.state.topSearchQueries.length > 0
        );
    }

    private hasMultipleSuggestionLists(): boolean {
        return (
            this.state.suggestions.some(
                (list) => list.clientSuggestion.length > 0
            ) && this.state.topSearchQueries.length > 0
        );
    }

    private resetSuggestionState(): void {
        this.setState(
            (
                state: SearchBoxTypes.ISearchBoxStates,
                props: SearchBoxTypes.ISearchBoxProps
            ) => ({
                ...state,
                suggestions: [],
                featurePromotion: "",
                topSearchQueries: []
            })
        );
    }

    private handleSubmit(event): void {
        event.preventDefault();
        if (this.state.searchTerm === "") {
            if (!this.searchBoxRef.current.classList.contains("flyout"))
                this.setState({ showModal: true, focus: false });
            this.inputRef.current.blur();
        } else
            window.location.href = `${
                this.props.l10n.searchTarget
            }?query=${encodeURIComponent(this.state.searchTerm)}`;
    }

    private handleClick(event) {
        if (!this.searchBoxRef.current.contains(event.target))
            this.deactivateFocus();
    }

    private activateFocus(event): void {
        let opaqueheaderdelay = 0;

        this.appShellWrapper.publishTo("SSW.expand", {});
        // tell HeadR that should show small logo
        this.appShellWrapper.publishTo("ESMKT.HeadR.ForceSmallLogo", true);

        if (!event.target.closest(".flyout")) {
            this.appShellWrapper.publishTo("ESMKT.HeadR.CloseFlyout", null);
            this.appShellWrapper.publishTo(
                "ESMKT.HeadR.ForceOpaqueHeader",
                true
            );
            opaqueheaderdelay = 200;
        }

        setTimeout(() => {
            this.setState(
                (
                    state: SearchBoxTypes.ISearchBoxStates,
                    props: SearchBoxTypes.ISearchBoxProps
                ) => ({
                    ...state,
                    focus: true
                })
            );
        }, opaqueheaderdelay);

        if (this.state.suggestions.length < 1 && this.state.searchTerm)
            this.fetchSuggestion(this.state.searchTerm);
    }

    private deactivateFocus(): void {
        this.appShellWrapper.publishTo("SSW.collapse", {});
        // tell HeadR that no need to force show small logo anymore
        this.appShellWrapper.publishTo("ESMKT.HeadR.ForceSmallLogo", false);

        setTimeout(() => {
            this.setState(
                (
                    state: SearchBoxTypes.ISearchBoxStates,
                    props: SearchBoxTypes.ISearchBoxProps
                ) => ({
                    ...state,
                    focus: false
                })
            );
            if (this.searchBoxRef.current.classList.contains("wide"))
                this.appShellWrapper.publishTo(
                    "ESMKT.HeadR.ForceOpaqueHeader",
                    false
                );
        }, 200);
    }

    private toggleVisibility(event): void {
        this.callAppShellEvent();
        this.setState(
            (
                state: SearchBoxTypes.ISearchBoxStates,
                props: SearchBoxTypes.ISearchBoxProps
            ) => ({
                ...state,
                visibility: !this.state.visibility
            })
        );
    }

    private callAppShellEvent(): void {
        if (this.state.visibility) {
            this.appShellWrapper.publishTo("SSW.collapse", {});
        } else {
            this.appShellWrapper.publishTo("SSW.expand", {});
        }
    }

    private getSuggestionUrl(): string {
        const culturePrefix: string = "/" + this.props.culture.split("-")[0];
        return culturePrefix + "/api/shopsearch-web/suggestion/";
    }

    private bindKeyboardNavigation(): void {
        if (!this.isDesktop) return;

        if (this.state.focus)
            document.addEventListener("keydown", this.handleKeyPressed);
        else document.removeEventListener("keydown", this.handleKeyPressed);
    }

    private handleKeyPressed(event: KeyboardEvent): void {
        switch (event.key) {
            case "Down":
            case "ArrowDown":
                event.preventDefault();
                if (this.suggestionRef.current != null)
                    this.suggestionRef.current.selectNextSuggestionEntry(false);
                break;

            case "Up":
            case "ArrowUp":
                event.preventDefault();
                if (this.suggestionRef.current != null)
                    this.suggestionRef.current.selectNextSuggestionEntry(true);
                break;

            case "Enter":
                if (this.suggestionRef.current != null) {
                    event.preventDefault();
                    const executedRedirect =
                        this.suggestionRef.current.triggerSuggestionRedirect();
                    if (!executedRedirect) this.handleSubmit(event);
                } else this.handleSubmit(event);
                break;

            case "Esc":
            case "Escape":
                if (this.suggestionRef.current != null) this.deactivateFocus();
                break;

            default:
                return;
        }
    }
}
