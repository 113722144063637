import Search2021 from "../../Assets/svg/Search_2021_icon";

import * as debounceSsw from "lodash/debounce";
import * as React from "react";
import Modal from "../modal/modal";
import * as SearchGridTypes from "../../types/searchboxgrid";

import Suggestions from "../suggestions/suggestions";
import "./searchboxgrid.scss";
import Search from "../../util/search";

export default class SearchBoxGrid extends Search<
    SearchGridTypes.ISearchBoxSearchGridProps,
    SearchGridTypes.ISearchBoxSearchGridStates
> {
    private suggestionWrapperRef = React.createRef<HTMLDivElement>();
    private scopeSsw: Element;

    constructor(props: any) {
        super(props);

        this.state = {
            focus: false,
            searchTerm: "",
            showModal: false,
            suggestions: [],
            featurePromotion: "",
            topSearchQueries: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeyPressed = this.handleKeyPressed.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.activateFocusHelper = this.activateFocusHelper.bind(this);
        this.activateFocus = this.activateFocus.bind(this);
        this.deactivateFocus = this.deactivateFocus.bind(this);
        this.updateSuggestionsVisibleStatus =
            this.updateSuggestionsVisibleStatus.bind(this);
    }

    public componentDidMount(): void {
        document.addEventListener("mousedown", this.handleClick, false);
        this.scopeSsw = this.searchBoxRef.current.closest(".scope-ssw");
    }

    public componentDidUpdate(): void {
        this.bindKeyboardNavigation();
        this.updateSuggestionsVisibleStatus();
    }

    public componentWillUnmount(): void {
        document.removeEventListener("mousedown", this.handleClick, false);
    }

    public render() {
        const notShowSuggestion =
            !this.areSuggestionsInResponse() || !this.state.searchTerm;
        const theme = this.props.theme ? " " + this.props.theme : "";

        return (
            <div
                className={
                    "searchbox-grid" +
                    ` ${this.state.focus ? "focus" : ""}` +
                    ` ${notShowSuggestion ? "" : "show-suggestions"}` +
                    ` ${this.props.tenant}` +
                    ` ${theme}`
                }
                ref={this.searchBoxRef}
            >
                <form
                    className={"search-grid"}
                    onSubmit={this.handleSubmit}
                    onClick={this.activateFocusHelper}
                >
                    <button type="submit" className="search-grid-button">
                        <Search2021 />
                    </button>
                    <span className="transition-wrapper">
                        <span
                            className="input-sizer"
                            data-value={
                                this.state.searchTerm ||
                                (this.state.focus
                                    ? ""
                                    : this.decodeHTML(
                                          this.props.l10n.placeholder
                                      ))
                            }
                        >
                            <input
                                ref={this.inputRef}
                                className="search-grid-input"
                                value={this.state.searchTerm}
                                onChange={this.handleChange}
                                onFocus={this.activateFocus}
                                placeholder={
                                    this.state.focus
                                        ? ""
                                        : this.decodeHTML(
                                              this.props.l10n.placeholder
                                          )
                                }
                                maxLength={50}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="none"
                                spellCheck="false"
                                size={1}
                            />
                        </span>
                    </span>
                </form>
                <hr />
                {
                    <div
                        className={
                            this.hasMultipleSuggestionLists()
                                ? "suggestions multiple-lists"
                                : "suggestions"
                        }
                        ref={this.suggestionWrapperRef}
                    >
                        <Suggestions
                            trackingEndpoint={this.props.trackingEndpoint}
                            portal={this.props.portal}
                            culture={this.props.culture}
                            ref={this.suggestionRef}
                            suggestions={this.state.suggestions}
                            searchTerm={this.state.searchTerm}
                            showMultipleSuggestionLists={this.hasMultipleSuggestionLists()}
                            articlesSuggestHeadline={this.decodeHTML(
                                this.props.l10n.articlesSuggestHeadline
                            )}
                            contentSuggestHeadline={this.decodeHTML(
                                this.props.l10n.contentSuggestHeadline
                            )}
                            featurePromotion={this.state.featurePromotion}
                            trouserFinderHeadline={this.decodeHTML(
                                this.props.l10n.trouserFinderHeadline
                            )}
                            trouserFinderCTA={this.decodeHTML(
                                this.props.l10n.trouserFinderCTA
                            )}
                            trouserFinderLink={this.decodeHTML(
                                this.props.l10n.trouserFinderLink
                            )}
                            trouserFinderShortLink={this.decodeHTML(
                                this.props.l10n.trouserFinderShortLink
                            )}
                            trouserFinderLogo={this.decodeHTML(
                                this.props.l10n.trouserFinderLogo
                            )}
                            jacketFinderCTA={this.decodeHTML(
                                this.props.l10n.jacketFinderCTA
                            )}
                            jacketFinderHeadline={this.decodeHTML(
                                this.props.l10n.jacketFinderHeadline
                            )}
                            jacketFinderLink={this.decodeHTML(
                                this.props.l10n.jacketFinderLink
                            )}
                            jacketFinderShortLink={this.decodeHTML(
                                this.props.l10n.jacketFinderShortLink
                            )}
                            jacketFinderLogo={this.decodeHTML(
                                this.props.l10n.jacketFinderLogo
                            )}
                            shoeFinderCTA={this.decodeHTML(
                                this.props.l10n.shoeFinderCTA
                            )}
                            shoeFinderHeadline={this.decodeHTML(
                                this.props.l10n.shoeFinderHeadline
                            )}
                            shoeFinderLink={this.decodeHTML(
                                this.props.l10n.shoeFinderLink
                            )}
                            shoeFinderShortLink={this.decodeHTML(
                                this.props.l10n.shoeFinderShortLink
                            )}
                            shoeFinderLogo={this.decodeHTML(
                                this.props.l10n.shoeFinderLogo
                            )}
                            topSearchQueries={this.state.topSearchQueries}
                            topSearchQueriesHeadline={this.decodeHTML(
                                this.props.l10n.topSearchQueriesHeadline
                            )}
                            searchTarget={this.props.l10n.searchTarget}
                        />
                    </div>
                }
                {this.isDesktop && (
                    <Modal
                        open={this.state.showModal}
                        tenant={this.props.tenant}
                        theme={this.props.theme}
                        closeModal={this.hideModal}
                        l10n={this.props.l10n}
                        featureToggles={this.props.featureToggles}
                    />
                )}
            </div>
        );
    }

    private handleSubmit(event): void {
        event.preventDefault();
        if (this.state.searchTerm === "") {
            this.setState({ showModal: true, focus: false });
            this.inputRef.current.blur();
        } else
            window.location.href = `${
                this.props.l10n.searchTarget
            }?query=${encodeURIComponent(this.state.searchTerm)}`;
    }

    private handleChange(event): void {
        if (!event || !event.target) {
            this.resetSuggestionState();
            return;
        }

        this.setState({ searchTerm: event.target.value });

        if (event.target.value) this.debouncedFetchSuggestions(event);
        else this.resetSuggestionState();
    }

    private debouncedFetchSuggestions(event): void {
        event.persist();

        if (!this.debouncedFetchSuggestionsFunc)
            this.debouncedFetchSuggestionsFunc = debounceSsw(() => {
                this.fetchSuggestion(event);
            }, 250);

        this.debouncedFetchSuggestionsFunc();
    }

    private fetchSuggestion(event): void {
        if (
            !event ||
            !event.target ||
            !event.target.value ||
            !event.target.value.trim().length
        ) {
            this.resetSuggestionState();
            return;
        }

        const searchTerm: string = event.target.value;
        const topSearchQueriesLimit: number = this.isDesktop ? 10 : 5;

        const headers = this.pageViewLogDataId.get()
            ? {
                  "X-PageView-ID": this.pageViewLogDataId.get(),
                  "X-Feature-Toggles": "ShopSearchEnableTopSearchQueries=1"
              }
            : {};

        fetch(
            `${this.getSuggestionUrl()}?searchTerm=${encodeURIComponent(
                searchTerm
            )}&topSearchQueriesLimit=${topSearchQueriesLimit}`,
            { headers }
        )
            .then((response) => response.json())
            .then(
                (response) => {
                    if (
                        (response && response.clientSuggestionsList?.length) ||
                        response.topSearchQueries?.length
                    ) {
                        this.setState({
                            suggestions: response.clientSuggestionsList,
                            featurePromotion: response.featurePromotion,
                            topSearchQueries: response.topSearchQueries
                        });
                    } else this.resetSuggestionState();
                },
                (error) => {
                    this.resetSuggestionState();
                }
            );
    }

    private areSuggestionsInResponse(): boolean {
        return (
            this.state.suggestions.some(
                (list) => list.clientSuggestion.length > 0
            ) || this.state.topSearchQueries.length > 0
        );
    }

    private hasMultipleSuggestionLists(): boolean {
        return (
            this.state.suggestions.some(
                (list) => list.clientSuggestion.length > 0
            ) && this.state.topSearchQueries.length > 0
        );
    }

    private resetSuggestionState(): void {
        this.setState(
            (
                state: SearchGridTypes.ISearchBoxSearchGridStates,
                props: SearchGridTypes.ISearchBoxSearchGridProps
            ) => ({
                ...state,
                suggestions: [],
                featurePromotion: "",
                topSearchQueries: []
            })
        );
    }

    private getSuggestionUrl(): string {
        const culturePrefix: string = "/" + this.props.culture.split("-")[0];
        return culturePrefix + "/api/shopsearch-web/suggestion/";
    }

    private decodeHTML(value: string): string {
        return value.replace(/&#(\d+);/g, (match, dec) => {
            return String.fromCharCode(dec);
        });
    }

    private handleClick(event): void {
        if (!this.searchBoxRef.current.contains(event.target))
            this.deactivateFocus();
    }

    private activateFocusHelper(): void {
        this.activateFocus();
        this.inputRef.current.focus();
    }

    private activateFocus(): void {
        if (this.state.focus) return;

        this.setState({ focus: true });

        if (this.isDesktop) this.scrollSearchboxToTopDesktop();
    }

    private deactivateFocus(): void {
        if (!this.state.focus) return;

        this.setState({ focus: false });
        this.inputRef.current.blur();
    }

    private bindKeyboardNavigation(): void {
        if (!this.isDesktop) return;

        if (this.state.focus)
            document.addEventListener("keydown", this.handleKeyPressed);
        else document.removeEventListener("keydown", this.handleKeyPressed);
    }

    private handleKeyPressed(event: KeyboardEvent): void {
        switch (event.key) {
            case "Down":
            case "ArrowDown":
                event.preventDefault();
                if (this.suggestionRef.current != null)
                    this.suggestionRef.current.selectNextSuggestionEntry(false);
                break;

            case "Up":
            case "ArrowUp":
                event.preventDefault();
                if (this.suggestionRef.current != null)
                    this.suggestionRef.current.selectNextSuggestionEntry(true);
                break;

            case "Enter":
                if (this.suggestionRef.current != null) {
                    event.preventDefault();
                    const executedRedirect =
                        this.suggestionRef.current.triggerSuggestionRedirect();
                    if (!executedRedirect) this.handleSubmit(event);
                } else this.handleSubmit(event);
                break;

            case "Esc":
            case "Escape":
                if (this.suggestionRef.current != null) this.deactivateFocus();
                break;

            default:
                return;
        }
    }

    private scrollSearchboxToTopDesktop(): void {
        // on small / little height devices e.g. tablets in landscape
        // scrolling the searchbox up to have more space for suggestions
        const offsetTop: number = this.searchBoxRef.current.offsetTop;
        const heightBelowIncludingSearch: number =
            window.innerHeight - offsetTop;

        if (heightBelowIncludingSearch > 590) return;

        window.scroll({
            behavior: "smooth",
            top: offsetTop - 55
        });
    }

    private updateSuggestionsVisibleStatus(): void {
        const suggestionsVisibleStatus: boolean =
            this.state.focus &&
            this.areSuggestionsInResponse() &&
            !!this.state.searchTerm;

        if (suggestionsVisibleStatus)
            this.scopeSsw.classList.add("suggestions-visible");
        else this.scopeSsw.classList.remove("suggestions-visible");
    }

    private hideModal = () => {
        this.setState({ showModal: false });
    };
}
