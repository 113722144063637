import * as React from "react";
import * as SuggestionsTypes from "../../types/suggestions";

import "./suggestions.scss";

import SuggestionList from "../suggestionlist/suggestionlist";

import { IPromotion } from "../../types/promotion";
import { ITopSearchQuery } from "../../types/topsearchquery";

export default class Suggestions extends React.Component<
    SuggestionsTypes.ISuggestionsProps,
    SuggestionsTypes.ISuggestionsState
> {
    private readonly promotions: Record<string, IPromotion> = {
        trouserfinder: {
            name: "TrouserFinder",
            logoUrl: this.props.trouserFinderLogo,
            headline: this.props.trouserFinderHeadline,
            cta: this.props.trouserFinderCTA,
            link: this.props.trouserFinderLink,
            shortLink: this.props.trouserFinderShortLink
        },
        jacketfinder: {
            name: "JacketFinder",
            logoUrl: this.props.jacketFinderLogo,
            headline: this.props.jacketFinderHeadline,
            cta: this.props.jacketFinderCTA,
            link: this.props.jacketFinderLink,
            shortLink: this.props.jacketFinderShortLink
        },
        shoefinder: {
            name: "ShoeFinder",
            logoUrl: this.props.shoeFinderLogo,
            headline: this.props.shoeFinderHeadline,
            cta: this.props.shoeFinderCTA,
            link: this.props.shoeFinderLink,
            shortLink: this.props.shoeFinderShortLink
        }
    };

    public leftListRef = React.createRef<SuggestionList>();
    public rightListRef = React.createRef<SuggestionList>();
    public singleListRef = React.createRef<SuggestionList>();
    private firstListLength: number = null;
    private secondListLength: number = null;
    private hasMulipleLists: boolean = null;

    constructor(props) {
        super(props);
        this.state = {
            isHidden: false
        };
    }

    public componentDidUpdate(prevProps): void {
        if (prevProps.searchTerm !== this.props.searchTerm) {
            this.setState({
                isHidden: false
            });
        }
    }

    public render() {
        this.hasMulipleLists = this.props.showMultipleSuggestionLists;
        const mappedSuggestions = this.mapSuggestions(this.props.suggestions);
        const mappedFeaturePromotion = this.getPromotion(
            this.props.featurePromotion
        );
        const mappedTopSearchQueries = this.mapTopSearchQueries(
            this.props.topSearchQueries
        );

        this.setListRanges(
            mappedTopSearchQueries.length,
            mappedSuggestions.length
        );

        return (
            <div className={"list-container"}>
                {this.hasMulipleLists ? (
                    <>
                        <SuggestionList
                            ref={this.leftListRef}
                            mappedSuggestions={[]}
                            mappedFeaturePromotion={mappedFeaturePromotion}
                            mappedTopSearchQueries={mappedTopSearchQueries}
                            firstListLength={this.firstListLength}
                            isSingleList={false}
                            isLeftList={true}
                            trackingEndpoint={this.props.trackingEndpoint}
                            portal={this.props.portal}
                            culture={this.props.culture}
                            searchTerm={this.props.searchTerm}
                        />
                        <SuggestionList
                            ref={this.rightListRef}
                            mappedSuggestions={mappedSuggestions}
                            mappedFeaturePromotion={null}
                            mappedTopSearchQueries={[]}
                            firstListLength={this.firstListLength}
                            isSingleList={false}
                            isLeftList={false}
                            trackingEndpoint={this.props.trackingEndpoint}
                            portal={this.props.portal}
                            culture={this.props.culture}
                            searchTerm={this.props.searchTerm}
                        />
                    </>
                ) : (
                    <SuggestionList
                        ref={this.singleListRef}
                        mappedSuggestions={mappedSuggestions}
                        mappedFeaturePromotion={mappedFeaturePromotion}
                        mappedTopSearchQueries={mappedTopSearchQueries}
                        firstListLength={0}
                        isSingleList={true}
                        isLeftList={true}
                        trackingEndpoint={this.props.trackingEndpoint}
                        portal={this.props.portal}
                        culture={this.props.culture}
                        searchTerm={this.props.searchTerm}
                    />
                )}
            </div>
        );
    }

    private setListRanges(
        topSearchQueriesLength: number,
        suggestionsLength: number
    ): void {
        if (this.hasMulipleLists) {
            this.firstListLength = topSearchQueriesLength;
            this.secondListLength = this.firstListLength + suggestionsLength;
        } else {
            this.firstListLength = suggestionsLength ?? topSearchQueriesLength;
        }
    }

    private getPromotion(
        featurePromotionName: string | null
    ): IPromotion | null {
        if (
            !this.isDesktop() ||
            featurePromotionName === null ||
            featurePromotionName === ""
        ) {
            return null;
        }

        const normalizedFeaturePromotionName =
            featurePromotionName.toLowerCase();

        return this.promotions[normalizedFeaturePromotionName] || null;
    }

    private isDesktop(): boolean {
        const device = this.props.portal.split(".")[0].toLowerCase();
        return device === "portal";
    }

    private mapTopSearchQueries(topSearchQueries: string[]): ITopSearchQuery[] {
        if (topSearchQueries.length === 0) return [];
        const result: ITopSearchQuery[] = [];

        result.push({
            value: this.props.topSearchQueriesHeadline,
            isHeadline: true,
            target: "",
            disableHighlighting: true,
            type: "TopSearchQuery"
        });

        topSearchQueries.forEach((query) => {
            const mappedTopSearchQuery: ITopSearchQuery = {
                value: query,
                isHeadline: false,
                target: `${this.props.searchTarget}?query=${encodeURIComponent(
                    query
                )}`,
                disableHighlighting: false,
                type: "TopSearchQuery"
            };
            result.push(mappedTopSearchQuery);
        });

        return result;
    }

    private mapSuggestions(
        suggestions: SuggestionsTypes.ISuggestions[]
    ): SuggestionsTypes.IMappedSuggestion[] {
        const result: SuggestionsTypes.IMappedSuggestion[] = [];

        for (const suggestionsList of suggestions) {
            if (
                suggestionsList.clientSuggestion &&
                suggestionsList.clientSuggestion.length > 0 &&
                this.suggestionListShouldBeShown(suggestionsList.type)
            ) {
                this.pushMappedSuggestion(
                    result,
                    true,
                    "",
                    "",
                    this.mapSuggestionsHeadlines(suggestionsList.type),
                    true,
                    suggestionsList.type.toLowerCase()
                );

                for (const suggestion of suggestionsList.clientSuggestion) {
                    this.pushMappedSuggestion(
                        result,
                        false,
                        suggestion.shortLink,
                        suggestion.target,
                        suggestion.value,
                        suggestion.disableHighlighting,
                        suggestionsList.type.toLowerCase()
                    );
                }
            }
        }

        return result;
    }

    private suggestionListShouldBeShown(type: string): boolean {
        return type === "Articles" || type === "Content";
    }

    private mapSuggestionsHeadlines(type: string): string {
        switch (type) {
            case "Articles":
                return this.props.articlesSuggestHeadline;
            case "Content":
                return this.props.contentSuggestHeadline;
        }
    }

    private pushMappedSuggestion(
        result: SuggestionsTypes.IMappedSuggestion[],
        isHeadline: boolean,
        shortLink: string,
        target: string,
        value: string,
        disableHighlighting: boolean,
        type: string
    ): void {
        const mappedSuggestion: SuggestionsTypes.IMappedSuggestion = {
            disableHighlighting,
            isHeadline,
            shortLink,
            target,
            value,
            type
        };
        result.push(mappedSuggestion);
    }

    public selectNextSuggestionEntry(isOpposite: boolean): void {
        this.setState({ isHidden: false });
        this.hasMulipleLists
            ? this.handleMultipleSuggestionLists(isOpposite)
            : this.singleListRef.current.selectNextSuggestionEntry(isOpposite);
    }

    private handleMultipleSuggestionLists(isOpposite: boolean): void {
        const currentLeftListIndex =
            this.leftListRef.current.currentSelectedIndex;
        const currentRightListIndex =
            this.rightListRef.current.currentSelectedIndex;

        if (currentLeftListIndex == null && currentRightListIndex == null) {
            isOpposite
                ? this.rightListRef.current.selectNextSuggestionEntry(
                      isOpposite
                  )
                : this.leftListRef.current.selectNextSuggestionEntry(
                      isOpposite
                  );
        } else if (currentLeftListIndex != null) {
            const indexToSelect = isOpposite
                ? currentLeftListIndex - 1
                : currentLeftListIndex + 1;

            // exclude indices which are not on the leftList and leftList's first headline
            if (
                indexToSelect <= 0 ||
                indexToSelect > this.firstListLength - 1
            ) {
                this.leftListRef.current.deselectEntry();
                this.rightListRef.current.selectNextSuggestionEntry(isOpposite);
            } else {
                this.leftListRef.current.selectNextSuggestionEntry(isOpposite);
            }
        } else if (currentRightListIndex != null) {
            const indexToSelect = isOpposite
                ? currentRightListIndex - 1
                : currentRightListIndex + 1;

            // exclude indices which are not on the rightList and rightList's first headline
            if (
                indexToSelect <= this.firstListLength ||
                indexToSelect > this.secondListLength - 1
            ) {
                this.rightListRef.current.deselectEntry();
                this.leftListRef.current.selectNextSuggestionEntry(isOpposite);
            } else {
                this.rightListRef.current.selectNextSuggestionEntry(isOpposite);
            }
        }
    }

    public triggerSuggestionRedirect(): boolean {
        if (this.hasMulipleLists) {
            if (this.leftListRef.current?.currentSelectedIndex != null) {
                this.leftListRef.current.triggerSuggestionRedirect();
            } else if (
                this.rightListRef.current?.currentSelectedIndex != null
            ) {
                this.rightListRef.current.triggerSuggestionRedirect();
            } else return false;
        } else {
            if (this.singleListRef.current?.currentSelectedIndex != null) {
                this.singleListRef.current.triggerSuggestionRedirect();
            } else return false;
        }
        return true;
    }
}
