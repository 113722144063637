export interface ISearchTelemetry {}

export interface ISearchTelemetryData {
    readonly eventType: EventType;
    setTerm(term: string): void;
}

export enum EventType {
    Unknown,
    HymUsed,
    SearchUsed
}
