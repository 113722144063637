// SearchTelemetry implemented as Singleton
// because it can be requested by each searchbox
// but only needed once
import { ApplicationShellWrapper } from "@escid-esmkt/applicationshellwrapper";

import PageViewLogDataId from "../pageviewlogdataid/pageviewlogdataid";
import * as PageViewLogDataIdTypes from "../../types/pageviewlogdataid";
import * as SearchTelemetryTypes from "../../types/searchtelemetry.d";
import SearchTelemetryData from "./searchtelemetrydata";

export default class SearchTelemetry
    implements SearchTelemetryTypes.ISearchTelemetry
{
    private static instance: SearchTelemetryTypes.ISearchTelemetry;
    private static headersOfTelemetryRequest = {
        type: "application/json"
    };

    private appShellWrapper: ApplicationShellWrapper;
    private readonly pageViewLogDataId: PageViewLogDataIdTypes.IPageViewLogDataId;
    private readonly portal: string;
    private readonly culture: string;
    private readonly telemetryObjects: HTMLElement[];
    private blockTelemetryEvent: boolean;

    constructor(portal: string, culture: string) {
        if (SearchTelemetry.instance)
            throw new Error(
                `Instantiation of SearchTelemetry failed:
                Use SearchTelemetry.getInstance(pageViewLogDataId, portal, culture)
                instead of new SearchTelemetry(pageViewLogDataId, portal, culture)`
            );

        this.appShellWrapper = ApplicationShellWrapper.getInstance();
        this.pageViewLogDataId = PageViewLogDataId.getInstance();
        this.portal = portal;
        this.culture = culture;

        const elements = document.querySelectorAll("[data-stm]");
        this.telemetryObjects = Array.prototype.slice.call(elements);

        this.bindOnElements();
    }

    public static getInstance(
        portal: string,
        culture: string
    ): SearchTelemetryTypes.ISearchTelemetry {
        if (!SearchTelemetry.instance)
            SearchTelemetry.instance = new SearchTelemetry(portal, culture);

        return SearchTelemetry.instance;
    }

    private bindOnElements(): void {
        this.bindOnButtons();
        this.bindOnLanguageSwitcher();
        this.bindOnArticleTiles(); // ESWF
        this.bindOnArticleLinks(); // ESMF
    }

    private bindOnButtons(): void {
        const buttons = this.telemetryObjects.filter((element: HTMLElement) =>
            element.classList.contains("button")
        );

        buttons.forEach((button: HTMLElement) => {
            this.addListenerToClickEvent(button, (event) =>
                this.clickOnButton(event)
            );
        });
    }

    private bindOnLanguageSwitcher(): void {
        const languageSwitcherBlock = this.telemetryObjects.filter(
            (element: HTMLElement) => element.classList.contains("block")
        );

        if (!languageSwitcherBlock.length) return;

        const blockElement: HTMLElement = languageSwitcherBlock[0];
        const stm = blockElement.dataset.stm;
        const languageSwitcherButtons = blockElement.querySelectorAll(
            ".mkt-ls-scope .mkt-switcher-button:not(.mkt-active)"
        );
        const languageSwitcherButtonsArr = Array.prototype.slice.call(
            languageSwitcherButtons
        );

        languageSwitcherButtonsArr.forEach(
            (languageSwitcherButton: HTMLElement) => {
                this.addListenerToClickEvent(languageSwitcherButton, (event) =>
                    this.clickOnLanguageSwitcherButton(event, stm)
                );
            }
        );
    }

    private bindOnArticleTiles(): void {
        const articleLists = document.querySelectorAll(".articlelist");
        const articleListsArr = Array.prototype.slice.call(articleLists);

        articleListsArr.forEach((articleList) => {
            const articleTiles = articleList.querySelector(
                ".article-tile[data-stm]"
            );

            if (articleTiles)
                this.addListenerToClickEvent(articleList, (event) =>
                    this.clickOnArticleTile(event)
                );
        });
    }

    private bindOnArticleLinks(): void {
        this.appShellWrapper.subscribeTo(
            "SSW.HYM.ArticleLinkClick",
            (payload) => {
                this.clickOnArticleLink(payload);
            },
            "SSW.HYM.ArticleLinkClick"
        );
    }

    private addListenerToClickEvent(
        element: HTMLElement,
        callback: (event: MouseEvent) => void
    ): void {
        const leftAndMiddleListener = (event: MouseEvent): void => {
            // only left or middle mouse button click
            if (event.button === 0 || event.button === 1) callback(event);
        };

        element.addEventListener("click", leftAndMiddleListener);
        element.addEventListener("auxclick", leftAndMiddleListener);
    }

    private clickOnButton(event): void {
        const currentTarget = event.currentTarget;
        const stm: string = currentTarget.dataset.stm;
        const data = this.constructTelemetryData(stm);

        this.postTelemetryData(data);
    }

    private clickOnLanguageSwitcherButton(event, stm: string): void {
        const data = this.constructTelemetryData(stm);
        const culture: string = event.currentTarget.dataset.culture;
        data.setTerm(culture);

        this.postTelemetryData(data);
    }

    private clickOnArticleTile(event): void {
        const target = event.target as HTMLElement;
        const articleTile: HTMLElement = target.closest(
            ".article-tile[data-stm]"
        );
        if (!(target.className === "article-tile" || articleTile)) return;

        const stm = articleTile.dataset.stm;

        if (!stm) return;

        const data = this.constructTelemetryData(stm);

        this.postTelemetryData(data);
    }

    private clickOnArticleLink(payload): void {
        const data = this.constructTelemetryData(payload);

        this.postTelemetryData(data);
    }

    private constructTelemetryData(
        stm: string
    ): SearchTelemetryTypes.ISearchTelemetryData {
        return new SearchTelemetryData(
            stm,
            this.pageViewLogDataId.get(),
            this.portal,
            this.culture
        );
    }

    private postTelemetryData(
        data: SearchTelemetryTypes.ISearchTelemetryData
    ): void {
        if (this.blockTelemetryEvent) return;

        this.blockTelemetryEvent = true;
        setTimeout(() => (this.blockTelemetryEvent = false), 1000);

        let route: string;

        switch (data.eventType) {
            case SearchTelemetryTypes.EventType.HymUsed:
                route = "/api/shopsearch-web/telemetry/hym/events";
                break;

            case SearchTelemetryTypes.EventType.SearchUsed:
                route = "/api/shopsearch-web/telemetry/search/events";
                break;

            default:
                throw new RangeError(
                    `Event type ${data.eventType} is not valid.`
                );
        }

        if (navigator.sendBeacon) {
            const payload = new Blob(
                [JSON.stringify(data)],
                SearchTelemetry.headersOfTelemetryRequest
            );
            navigator.sendBeacon(route, payload);
            return;
        }

        setTimeout(() => {
            fetch(route, {
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST"
            });
        }, 0);
    }
}
